import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { jwtDecode } from 'jwt-decode';
import { BiddingResponse, RefreshTokenReponseType } from '../types';

const customAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

customAxios.interceptors.request.use(async (config) => {
  const accessToken = localStorage.getItem('accessToken');

  if (accessToken) {
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  }
  return config;
});

createAuthRefreshInterceptor(customAxios, async (failedRequest) => {
  const refreshToken = localStorage.getItem('refreshToken');

  const response = await customAxios.post<
    BiddingResponse<RefreshTokenReponseType>
  >(`/auth/refresh`, {
    refreshToken,
  });
  const { accessToken } = response.data.data;
  failedRequest.response.config.headers['Authorization'] =
    `Bearer ${accessToken}`;
  localStorage.setItem('accessToken', accessToken);
  localStorage.setItem(
    'decodedAccessToken',
    JSON.stringify(jwtDecode(accessToken))
  );
});

export default customAxios;
