import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { jwtDecode } from "jwt-decode";
import { AppDispatch, RootState } from '../store';
import { AuthData, AuthState } from '../../types/auth';
import authService from '../../services/auth-service';

const initialState: AuthState = {
  authenticated: false,
  accessToken: undefined,
  decodedAccessToken: undefined,
  refreshToken: undefined,
};

export const authSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.authenticated = true;
      state.accessToken = action.payload;
      state.decodedAccessToken = JSON.stringify(jwtDecode(action.payload));
    },
    logout: (state) => {
      state.authenticated = false;
      state.accessToken = undefined;
      state.decodedAccessToken = undefined;
    },
    setAuthData(state, action: PayloadAction<AuthData>) {
      const { authenticated, accessToken, refreshToken } = action.payload;
      state.authenticated = authenticated;
      state.accessToken = accessToken != null ? accessToken : undefined;
      state.refreshToken = refreshToken != null? refreshToken : undefined;
      if(accessToken) {
        state.decodedAccessToken = JSON.stringify(jwtDecode(accessToken));
      }
      else {
        state.decodedAccessToken = undefined;
      }
    }
  },
});

export const selectAuthState = (state: RootState) => state.auth;

export const getAccessToken =
  (email: string, password: string) => async (dispatch: AppDispatch) => {
    const { setAccessToken } = authSlice.actions;
    const tokens = await authService.getAccessToken(email, password);
    localStorage.setItem('isAuthenticated', 'true');
    localStorage.setItem('accessToken', tokens.accessToken);
    localStorage.setItem('decodedAccessToken', JSON.stringify(jwtDecode(tokens.accessToken)));
    localStorage.setItem('refreshToken', tokens.refreshToken);
    dispatch(setAccessToken(tokens.accessToken));
  };

export const logout = () => async (dispatch: AppDispatch) => {
  const { logout } = authSlice.actions;
  localStorage.setItem('isAuthenticated', 'false');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('decodedAccessToken');
  localStorage.removeItem('refreshToken');
  dispatch(logout());
};

export const loadAuthData = () => async (dispatch: AppDispatch) => {
  const { setAuthData } = authSlice.actions;
  const authenticated = localStorage.getItem('isAuthenticated') === "true";
  const accessToken = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');
  dispatch(setAuthData({ authenticated, accessToken, refreshToken }));
}

export default authSlice.reducer;
