import InfiniteScroll from 'react-infinite-scroll-component';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  loadMoreMessages,
  selectChatState,
} from '../../redux/slices/chat-slices';
import MessageItem from './MessageItem';

const MessageList = () => {
  const { messages } = useAppSelector(selectChatState);
  const dispatch = useAppDispatch();

  return (
    <div className="h-full overflow-hidden py-2 mt-[70px]">
      <div className="h-full overflow-y-auto">
        <InfiniteScroll
          dataLength={messages.length}
          loader={<></>}
          hasMore={true}
          next={() => dispatch(loadMoreMessages())}
        >
          {messages.map((message, index) => (
            <MessageItem key={`${message.id}-${index}`} message={message} />
          ))}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default MessageList;
