import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  getConversations,
  selectChatState,
} from '../../redux/slices/chat-slices';
import ConversationItem from './ConversationItem';
import ConversationListHeader from './ConversationListHeader';
import ConversationSearchBar from './ConversationSearchBar';
import Menu from './Menu';
import { selectAuthState } from '../../redux/slices/auth-slice';

const ConversationList = () => {
  const { conversationList, selectedConversation, searchQuery } =
    useAppSelector(selectChatState);

  const { authenticated } = useAppSelector(selectAuthState);

  const dispatch = useAppDispatch();

  const unreadCount = conversationList
    .map((conversation) => conversation.unreadCount)
    .reduce((previous, current) => previous + current, 0);

  useEffect(() => {
    if (authenticated) dispatch(getConversations());
  }, [dispatch, authenticated]);

  console.log(searchQuery);

  return (
    <div
      className={`transition-all duration-500 ${selectedConversation ? 'overflow-hidden w-0 opacity-0 py-2' : 'w-full sm:w-96 p-2'} sm:flex flex-row flex-shrink-0 bg-gray-100`}
    >
      <div className="flex flex-col w-full">
        <ConversationListHeader unreadCount={unreadCount} />
        <Menu />
        <ConversationSearchBar />
        <div className="flex flex-col">
          {conversationList.map((conversation, index) => (
            <ConversationItem
              key={`${index}-${conversation.id}`}
              conversation={conversation}
              selected={conversation.id === selectedConversation?.id}
              showUnreadCount={searchQuery === ''}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ConversationList;
