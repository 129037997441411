import axios from './custom-axios';
import { Conversation, Message } from '../types/chat';
import { BiddingResponse } from '../types';

const getConversations = async (): Promise<Conversation[]> => {
  const response =
    await axios.get<BiddingResponse<Conversation[]>>('/conversation');
  return response.data.data;
};

const searchConversations = async (
  query: string,
  limit?: number
): Promise<Conversation[]> => {
  const response = await axios.get<BiddingResponse<Conversation[]>>(
    `/conversation/search?query=${query}&limit=${limit ? limit : 50}`
  );
  return response.data.data;
};

const getMessages = async (
  conversationId: number,
  limit?: number,
  page?: number
): Promise<Message[]> => {
  const response = await axios.get<BiddingResponse<Message[]>>(
    `/message?conversationId=${conversationId}&limit=${limit ? limit : 20}&page=${page ? page : 0}`
  );
  return response.data.data;
};

const chatService = { getConversations, searchConversations, getMessages };

export default chatService;
