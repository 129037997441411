import { useAppDispatch } from '../../redux/hooks';
import { selectConversation } from '../../redux/slices/chat-slices';
import { Conversation } from '../../types';
import humanDate from '../../utils/human-date';

type PropsType = {
  conversation: Conversation;
  selected: boolean;
  showUnreadCount: boolean;
};

const ConversationItem = ({
  conversation,
  selected,
  showUnreadCount,
}: PropsType) => {
  const dispatch = useAppDispatch();

  const jumpToBidding = (biddingId: number) => {
    if (biddingId) {
      const lastConversation = document.getElementById(
        `m-${biddingId}`
      );
      lastConversation?.scrollIntoView();
    }
  }

  const handleConversationSelect = async () => {
    await dispatch(selectConversation(conversation));
    jumpToBidding(conversation.lastMessageBiddingId);
  };

  return (
    <div
      onClick={handleConversationSelect}
      className={`relative flex flex-row items-center px-1 py-4 ${selected ? 'bg-gradient-to-r from-green-100 to-transparent border-l-2 border-green-500' : 'border-l-2 border-gray-100'}`}
    >
      <div className="absolute text-xs text-gray-500 right-0 top-0 mr-4 mt-1">
        {humanDate(conversation.lastMessageDateTime)}
      </div>
      <div className="flex items-center justify-center h-10 w-10 rounded-full bg-teal-500 text-teal-300 font-bold flex-shrink-0">
        {conversation.senderAvatar}
      </div>
      <div className="flex flex-col flex-grow ml-3">
        <div className="text-sm font-medium">{conversation.sender}</div>
        <div className="text-xs truncate w-40">{conversation.lastMessage}</div>
        <div className="text-xs truncate w-40">{conversation.lastEntity}</div>
        <div className="text-xs truncate w-40">
          {conversation.lastMessageBiddingCode}
        </div>
      </div>
      {showUnreadCount && (
        <div className="flex-shrink-0 ml-2 self-end mb-1">
          <span className="flex items-center justify-center h-5 px-2 bg-green-500 text-white text-xs rounded-full">
            {conversation.unreadCount}
          </span>
        </div>
      )}
    </div>
  );
};

export default ConversationItem;
