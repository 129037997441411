import MenuButton from './MenuButton';

type PropsType = {
  unreadCount: number;
};

const ConversationListTitle = ({ unreadCount }: PropsType) => {
  return (
    <div className="flex flex-row w-full justify-between">
      <div className="flex flex-row items-center">
        <div className="text-xl font-semibold">Licitações</div>
        <div className="flex items-center justify-center ml-2 text-xs h-5 px-2 text-white bg-green-500 rounded-full font-medium">
          {unreadCount}
        </div>
      </div>
      <MenuButton />
    </div>
  );
};

export default ConversationListTitle;
