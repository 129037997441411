const TermsOfServicePage = () => {
  return (
    <div className="bg-gray-100 min-h-screen">
      <header className="bg-green-500 py-4">
        <div className="container mx-auto">
          <h1 className="text-white text-2xl font-bold">Torres Licitações</h1>
        </div>
      </header>
      <main className="container mx-auto py-8">
        <section className="mt-8">
          <h2 className="text-2xl font-semibold mb-4">Termos de Serviço</h2>
          <p className="text-lg">
            Ao utilizar este site, você concorda em cumprir nossos Termos de
            Serviço. Isso inclui, mas não se limita a:
          </p>
          <ul className="list-disc ml-8 text-lg">
            <li>Utilizar as informações fornecidas apenas para fins lícitos.</li>
            <li>
              Respeitar os direitos de propriedade intelectual do conteúdo
              exibido neste site.
            </li>
            <li>
              Não se envolver em atividades que possam prejudicar o site ou
              seus usuários.
            </li>
          </ul>
          <p className="text-lg mt-4">
            Este site fornece informações sobre licitações governamentais para seus usuários. Se precisar de mais informações ou tiver dúvidas sobre nossos serviços, entre em contato conosco:
          </p>
          <p className="text-lg mt-2">
            <strong>Empresa:</strong> A R PAULINO DE MOURA - ME <br />
            <strong>CNPJ:</strong> 14.631.389/0001-88 <br />
            <strong>Endereço:</strong> Rua Acélio Pitombeira, S/N, Centro, Apuiarés-CE, CEP: 62.630-000 <br />
            <strong>Fone:</strong> (85) 99186.6314
          </p>
          <p className="text-lg mt-4">
            Por favor, revise nossa{' '}
            <a
              href="/privacy-policy"
              className="text-blue-500 hover:underline"
            >
              Política de Privacidade
            </a>{' '}
            para obter informações sobre como coletamos, usamos e divulgamos
            seus dados pessoais.
          </p>
        </section>
      </main>
      <footer className="bg-gray-300 py-4 mt-8">
        <div className="container mx-auto text-center">
          <p>&copy; 2024 Torres Licitações. Todos os direitos reservados.</p>
        </div>
      </footer>
    </div>
  );
};

export default TermsOfServicePage;
