import { MouseEvent } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { logout } from '../../redux/slices/auth-slice';
import { RootState } from '../../redux/store';
import { setMenuOpened } from '../../redux/slices/chat-slices';

const Menu = () => {
  const { menuOpened } = useAppSelector((state: RootState) => state.chat);
  const dispatch = useAppDispatch();

  const handleLogout = (event: MouseEvent) => {
    dispatch(logout());
    dispatch(setMenuOpened(false));
    event.stopPropagation();
  };

  return (
    <div
      className={`transition-all duration-500 navbar-menu w-full mb-3 ${menuOpened ? 'h-12 opacity-100' : 'overflow-hidden h-0 opacity-0'}`}
    >
      <nav className="flex rounded top-0 left-0 w-full flex flex-col py-2 px-2 hover:bg-green-700 bg-green-500 text-white font-bold uppercase border-r">
        <div>
          <ul>
            <li className="mb-1 text-center" onClick={handleLogout}>
              Sair
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Menu;
