import { Bidding } from '../../types';
import humanDate from '../../utils/human-date';

type PropsType = {
  bidding: Bidding;
};

const BiddingTable = ({ bidding }: PropsType) => {
  return (
    <div>
      <table className="min-w-full bg-indigo-50 shadow-md rounded-xl">
        <tbody className="text-blue-gray-900">
          <tr className="border-b border-blue-gray-200">
            <td className="py-3 px-4 font-bold w-2/12">Entidade ou órgão</td>
            <td className="py-3 px-4">{bidding.entity}</td>
          </tr>
          <tr className="border-b border-blue-gray-200">
            <td className="py-3 px-4 font-bold">Modalidade</td>
            <td className="py-3 px-4">{bidding.modality}</td>
          </tr>
          <tr className="border-b border-blue-gray-200">
            <td className="py-3 px-4 font-bold">Situação</td>
            <td className="py-3 px-4">{bidding.status}</td>
          </tr>
          <tr className="border-b border-blue-gray-200">
            <td className="py-3 px-4 font-bold">Observação</td>
            <td className="py-3 px-4">{bidding.comment}</td>
          </tr>
          <tr className="border-b border-blue-gray-200">
            <td className="py-3 px-4 font-bold">Número</td>
            <td className="py-3 px-4">{bidding.code}</td>
          </tr>
          <tr className="border-b border-blue-gray-200">
            <td className="py-3 px-4 font-bold">Objeto</td>
            <td className="py-3 px-4">{bidding.subject}</td>
          </tr>
          <tr className="border-b border-blue-gray-200">
            <td className="py-3 px-4 font-bold">Ramo do objeto</td>
            <td className="py-3 px-4">{bidding.field}</td>
          </tr>
          <tr className="border-b border-blue-gray-200">
            <td className="py-3 px-4 font-bold">Dt. abertura</td>
            <td className="py-3 px-4">{humanDate(bidding.openDate)}</td>
          </tr>
          <tr className="border-b border-blue-gray-200">
            <td className="py-3 px-4 font-bold">Hr. abertura</td>
            <td className="py-3 px-4">{bidding.openHour}</td>
          </tr>
          <tr className="border-b border-blue-gray-200">
            <td className="py-3 px-4 font-bold">Dt. publicação</td>
            <td className="py-3 px-4">{humanDate(bidding.publishDate)}</td>
          </tr>
          <tr className="border-b border-blue-gray-200">
            <td className="py-3 px-4 font-bold">Edital</td>
            <td className="py-3 px-4">{bidding.notice}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default BiddingTable;
