import { MouseEvent, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setMenuOpened } from '../../redux/slices/chat-slices';
import { RootState } from '../../redux/store';
import { useNavigate } from 'react-router-dom';

const MenuButton = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { authenticated } = useAppSelector((state: RootState) => state.auth);
  const { menuOpened } = useAppSelector((state: RootState) => state.chat);

  useEffect(() => {
    if (!authenticated) {
      navigate('/login');
    }
  }, [authenticated, navigate]);

  const toggleMenu = (event: MouseEvent) => {
    dispatch(setMenuOpened(!menuOpened));
    event.stopPropagation();
  };

  return (
    <div className="flex items-center justify-center" onClick={toggleMenu}>
      <svg
        className={`${menuOpened ? 'h-0 w-0 opacity-0' : 'h-4 w-4 opacity-100'} block transition-all duration-1000 fill-current`}
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
      </svg>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className={`${menuOpened ? ' h-6 w-6 opacity-100' : 'h-0 w-0 opacity-0'} transition-all duration-1000`}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M6 18 18 6M6 6l12 12"
        />
      </svg>
    </div>
  );
};

export default MenuButton;
