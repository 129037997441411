import { useAppDispatch } from '../../redux/hooks';
import { unselectConversation } from '../../redux/slices/chat-slices';
import { Conversation } from '../../types';

type PropsType = {
  conversation?: Conversation;
};

const ConversationPanelHeader = ({ conversation }: PropsType) => {
  const dispatch = useAppDispatch();
  if (!conversation) return <></>;
  else
    return (
      <div className="fixed w-full flex flex-row items-center py-4 shadow z-10 bg-gray-100 top-0 left-0">
        <div
          className="w-10 h-10 flex flex-row items-center justify-center"
          onClick={() => dispatch(unselectConversation())}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="3.0"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 19.5 8.25 12l7.5-7.5"
            />
          </svg>
        </div>
        <div className="flex flex-col ml-3">
          <div className="font-semibold text-sm">{conversation.sender}</div>
        </div>
      </div>
    );
};

export default ConversationPanelHeader;
