import { useAppSelector } from '../../redux/hooks';
import { selectChatState } from '../../redux/slices/chat-slices';
import ConversationPanelHeader from './ConversationPanelHeader';
import MessageList from './MessageList';

const ConversationPanel = () => {
  const { selectedConversation } = useAppSelector(selectChatState);

  return (
    <div
      className={`flex flex-col h-full w-full bg-white ${selectedConversation ? 'p-2' : ''}`}
    >
      <ConversationPanelHeader conversation={selectedConversation} />
      <MessageList />
    </div>
  );
};

export default ConversationPanel;
