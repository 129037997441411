import ConversationPanel from '../components/conversation-panel/ConversationPanel';
import ConversationList from '../components/conversation-list/ConversationList';

const ChatPage = () => {
  return (
    <div className="flex flex-row antialiased text-gray-800">
      <ConversationList />
      <ConversationPanel />
    </div>
  );
};

export default ChatPage;
