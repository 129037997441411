import ConversationListTitle from './ConversationListTitle';

type PropsType = {
  unreadCount: number;
};

const ConversationListHeader = ({ unreadCount }: PropsType) => {
  return (
    <div className="flex flex-row items-center mb-3">
      <ConversationListTitle unreadCount={unreadCount} />
    </div>
  );
};

export default ConversationListHeader;
