const PrivacyPolicyPage = () => {
  return (
    <div className="bg-gray-100 min-h-screen">
      <header className="bg-green-500 py-4">
        <div className="container mx-auto">
          <h1 className="text-white text-2xl font-bold">Torres Licitações</h1>
        </div>
      </header>
      <main className="container mx-auto py-8">
        <section className="mt-8">
          <h2 className="text-2xl font-semibold mb-4">Política de Privacidade</h2>
          <p className="text-lg">
            Nossa política de privacidade descreve como coletamos, usamos e protegemos suas informações pessoais quando você utiliza nosso site. Leia com atenção para entender nossas práticas em relação aos seus dados pessoais.
          </p>
          <p className="text-lg mt-4">
            Esta política se aplica apenas às informações coletadas por este site. Ela não se aplica a informações coletadas por outras fontes, incluindo sites de terceiros ligados a partir deste site.
          </p>
          <p className="text-lg mt-4">
            Para mais detalhes, consulte nossa{' '}
            <a
              href="/terms-of-service"
              className="text-blue-500 hover:underline"
            >
              Termos de Serviço
            </a>
            .
          </p>
        </section>
      </main>
      <footer className="bg-gray-300 py-4 mt-8">
        <div className="container mx-auto text-center">
          <p>&copy; 2024 Torres Licitações. Todos os direitos reservados.</p>
        </div>
      </footer>
    </div>
  );
};

export default PrivacyPolicyPage;
