import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ChatState, Conversation, Message } from '../../types';
import { AppDispatch, RootState } from '../store';
import chatService from '../../services/chat-service';

const initialState: ChatState = {
  selectedConversation: undefined,
  searchQuery: '',
  menuOpened: false,
  conversationList: [],
  page: 0,
  messages: [],
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    selectConversation: (state, action: PayloadAction<Conversation>) => {
      state.selectedConversation = action.payload;
    },
    unselectConversation: (state) => {
      state.selectedConversation = undefined;
      state.messages = [];
    },
    setMessages: (state, action: PayloadAction<Message[]>) => {
      state.messages = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setSearchQuery: (state, action: PayloadAction<string>) => {
      state.searchQuery = action.payload;
    },
    setMenuOpened: (state, action: PayloadAction<boolean>) => {
      state.menuOpened = action.payload;
    },
    setConversationList: (state, action: PayloadAction<Conversation[]>) => {
      state.conversationList = action.payload;
    },
  },
});

export const { unselectConversation, setMenuOpened } = chatSlice.actions;

export const selectChatState = (state: RootState) => state.chat;

export const getConversations = () => async (dispatch: AppDispatch) => {
  const { setConversationList } = chatSlice.actions;
  const conversations = await chatService.getConversations();
  dispatch(setConversationList(conversations));
};

export const selectConversation =
  (conversation: Conversation) => async (dispatch: AppDispatch) => {
    const { selectConversation, setMessages, setPage } = chatSlice.actions;
    dispatch(selectConversation(conversation));
    const messages = await chatService.getMessages(conversation.id);
    dispatch(setMessages(messages));
    dispatch(setPage(0));
  };

export const loadMoreMessages =
  () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const { setMessages, setPage } = chatSlice.actions;
    const {
      chat: { selectedConversation, page, messages },
    } = getState();
    if (!selectedConversation?.id) return;

    const newPage = page + 1;
    const newMessages = await chatService.getMessages(
      selectedConversation.id,
      20,
      newPage
    );

    dispatch(setMessages([...messages, ...newMessages]));
    dispatch(setPage(newPage));
  };

export const setSearchQuery =
  (query: string) => async (dispatch: AppDispatch) => {
    const { setSearchQuery, setConversationList } = chatSlice.actions;
    dispatch(setSearchQuery(query));
    if (query) {
      const conversations = await chatService.searchConversations(query);
      dispatch(setConversationList(conversations));
    } else {
      const conversations = await chatService.getConversations();
      dispatch(setConversationList(conversations));
    }
  };

export default chatSlice.reducer;
