import { Message } from '../../types';
import humanDate from '../../utils/human-date';
import BiddingTable from './BiddingTable';

type PropsType = {
  message: Message;
};

const MessageItem = ({ message: { bidding, date } }: PropsType) => {

  return (
    <div className="py-2 mb-3 rounded-lg" id={`m-${bidding.id}`}>
      <div className="relative text-sm rounded-xl">
        <BiddingTable bidding={bidding} />
        <div className="absolute text-xs bottom-0 right-0 -mb-5 mr-2 text-gray-500">
          {humanDate(date)}
        </div>
      </div>
    </div>
  );
};

export default MessageItem;
