import { LoaderFunctionArgs, redirect } from 'react-router-dom';

const authProtectLoader = ({ request }: LoaderFunctionArgs) => {
  const isAuthenticated = localStorage.getItem('isAuthenticated');
  if (isAuthenticated === 'false' || isAuthenticated === null) {
    let params = new URLSearchParams();
    params.set('from', new URL(request.url).pathname);
    return redirect('/login?' + params.toString());
  }
  return null;
};

export default authProtectLoader;
