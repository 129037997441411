import { Outlet, createBrowserRouter } from 'react-router-dom';
import { OAuthPopup } from '@tasoskakour/react-use-oauth2';
import ChatPage from '../pages/ChatPage';
import LoginPage from '../pages/LoginPage';
import PrivacyPolicyPage from '../pages/PrivacyPolicyPage';
import TermsOfServicePage from '../pages/TermsOfServicePage';
import authProtectLoader from './auth-protect-loader';


const router = createBrowserRouter([
  {
    id: 'root',
    path: '/',
    Component: Outlet,
    children: [
      {
        index: true,
        loader: authProtectLoader,
        Component: ChatPage,
      },
      {
        path: '/login',
        Component: LoginPage,
      },
      {
        path: '/privacy-policy',
        Component: PrivacyPolicyPage,
      },
      {
        path: '/terms-of-service',
        Component: TermsOfServicePage,
      },
      {
        path: 'google-callback',
        Component: OAuthPopup
      }
    ],
  },
]);

export default router;
