import { RouterProvider } from 'react-router-dom';

import router from './routes';
import { useAppDispatch } from './redux/hooks';
import { loadAuthData } from './redux/slices/auth-slice';

const App = () => {
  const dispatch = useAppDispatch();


  const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";
  if(isAuthenticated) {
    dispatch(loadAuthData())
  }

  return (
    <RouterProvider router={router} />
  );
};

export default App;
