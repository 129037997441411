import { configureStore } from '@reduxjs/toolkit';
import chatReducer from './slices/chat-slices';
import authReducer from './slices/auth-slice';

const store = configureStore({
  reducer: {
    chat: chatReducer,
    auth: authReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
