import { useDebouncedCallback } from 'use-debounce';
import SearchButton from './SearchButton';
import { useAppDispatch } from '../../redux/hooks';
import { setSearchQuery } from '../../redux/slices/chat-slices';

const ConversationSearchBar = () => {
  const dispatch = useAppDispatch();

  const debounced = useDebouncedCallback((value: string) => {
    dispatch(setSearchQuery(value));
  }, 1000);

  return (
    <div className="flex flex-row bg-white text-gray-400 items-center w-full border rounded-3xl h-12 px-2 mb-3">
      <SearchButton />
      <div className="w-full">
        <input
          type="text"
          className="border border-transparent w-full focus:outline-none text-sm h-10 flex rounded-3xl items-center"
          placeholder="Localizar licitação...."
          onChange={(e) => debounced(e.target.value)}
        />
      </div>
    </div>
  );
};

export default ConversationSearchBar;
