import axios from './custom-axios';
import {
  GetAccessTokenReponseType,
  RefreshTokenReponseType,
} from '../types/auth';
import { BiddingResponse } from '../types';

const getAccessToken = async (
  email: string,
  password: string
): Promise<GetAccessTokenReponseType> => {
  const response = await axios.post<BiddingResponse<GetAccessTokenReponseType>>(
    `/auth/token`,
    {
      email,
      password,
    }
  );

  return response.data.data;
};

const refreshToken = async (
  refreshToken: string
): Promise<RefreshTokenReponseType> => {
  const response = await axios.post<BiddingResponse<RefreshTokenReponseType>>(
    `/auth/refresh`,
    {
      refreshToken,
    }
  );

  return response.data.data;
};

const authService = { getAccessToken, refreshToken };

export default authService;
