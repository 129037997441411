const SearchButton = () => {
  return (
    <div className="ml-auto">
      <button className="flex items-center justify-center h-7 w-7 rounded-full">
        <svg
          className="w-4 h-4 stroke-current"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          ></path>
        </svg>
      </button>
    </div>
  );
};

export default SearchButton;
